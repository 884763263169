import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ct700 from '../../../app/assets/img/work/company-thread/main-700.png'
import ct from '../../../app/assets/img/work/company-thread/main.png'
import ct250 from '../../../app/assets/img/work/company-thread/main-250.png'
import ct500 from '../../../app/assets/img/work/company-thread/main-500.png'

import bw700 from '../../../app/assets/img/work/bandwaggon/main-700.png'
import bw from '../../../app/assets/img/work/bandwaggon/main.png'
import bw250 from '../../../app/assets/img/work/bandwaggon/main-250.png'
import bw500 from '../../../app/assets/img/work/bandwaggon/main-500.png'

import sc700 from '../../../app/assets/img/work/scherago/main-700.png'
import sc from '../../../app/assets/img/work/scherago/main.png'
import sc250 from '../../../app/assets/img/work/scherago/main-250.png'
import sc500 from '../../../app/assets/img/work/scherago/main-500.png'
import AboutSite from "../components/about-site";

const IndexPage = ({ data }) => {
    const { allMdx: { edges }} = data;
    const posts = edges.map(edge => edge.node.frontmatter);

    return <Layout>
        <SEO title="Home"/>
        <section className="landing">
            <div className="post-list post-list-recent">
                <h2>Recent Posts</h2>
                {posts.map(post =>
                    <h3 key={post.slug}>
                        <Link to={post.slug}>
                            {post.title}
                        </Link>
                    </h3>
                )}
            </div>
            <div className="featured-work clearfix">
                <h2>Featured Work</h2>
                <ul className="list-unstyled">
                    <li>
                        <h4>
                            <Link to="work/company-thread" className="link-img">
                                <span className="title">CompanyThread</span>
                                <img src={ct700}
                                     srcSet={`${ct700} 700w,
                                       ${ct} 1000w,
                                       ${ct250} 250w,
                                       ${ct500} 500w`}
                                     sizes="(min-width: 1600px) 14vw, (min-width: 1200px) 18.66vw, (min-width: 700px) 33.3vw, 100vw"
                                     alt=""/>
                            </Link>
                        </h4>
                    </li>
                    <li>
                        <h4>
                            <Link to="work/bandwaggon" className="link-img">
                                <span className="title">Bandwaggon</span>
                                <img src={bw700}
                                     srcSet={`${bw700} 700w,
                                       ${bw} 1000w,
                                       ${bw250} 250w,
                                       ${bw500} 500w`}
                                     sizes="(min-width: 1600px) 14vw, (min-width: 1200px) 18.66vw, (min-width: 700px) 33.3vw, 100vw"
                                     alt=""/>
                            </Link>
                        </h4>
                    </li>
                    <li>
                        <h4>
                            <Link to="work/scherago" className="link-img">
                                <span className="title">Scherago</span>
                                <img src={sc700}
                                     srcSet={`${sc700} 700w,
                                       ${sc} 1000w,
                                       ${sc250} 250w,
                                       ${sc500} 500w`}
                                     sizes="(min-width: 1600px) 14vw, (min-width: 1200px) 18.66vw, (min-width: 700px) 33.3vw, 100vw"
                                     alt=""/>
                            </Link>
                        </h4>
                    </li>
                </ul>
            </div>
            <AboutSite/>
        </section>
    </Layout>
};

export default IndexPage

export const pageQuery = graphql`{
  allMdx(
    sort: { order: DESC, fields: [frontmatter___date] },
    limit: 4
  ) {
    edges {
      node {
        frontmatter {
          date,
          slug
          title,
          tags,
          description
        }
      }
    }
  }
}`;